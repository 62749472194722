import { render, staticRenderFns } from "./mcqQuestionView.vue?vue&type=template&id=37c767f1&scoped=true&"
import script from "./mcqQuestionView.vue?vue&type=script&lang=js&"
export * from "./mcqQuestionView.vue?vue&type=script&lang=js&"
import style0 from "./mcqQuestionView.vue?vue&type=style&index=0&id=37c767f1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c767f1",
  null
  
)

export default component.exports