<template>
  <div>
    <v-layout wrap v-if="questions.length > 0">
      <v-flex xs12>
        <v-form>
          <v-card-text>
            <v-container>
              <v-layout
                wrap
                justify-center
                v-for="(list, l) in questions"
                :key="l"
              >
                <v-flex xs10 sm11 pa-2>
                  <v-card>
                    <v-layout wrap justify-center pa-5>
                      <v-flex xs12 sm12 md12>
                        <v-layout wrap justify-end>
                          <v-flex xs12 sm11 text-right pa-2>
                            <span
                              style="
                                font-size: 15px;
                                font-family: poppinsmedium;
                              "
                              >Change Status</span
                            >
                          </v-flex>
                          <v-flex xs12 sm2 md1 text-right pa-2>
                            <v-btn
                              color="green"
                              small
                              depressed
                              @click="statusChange(list)"
                              ><span
                                style="color: white; font-family: poppinsmedium"
                                >{{ list.status }}</span
                              ></v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-5>
                        <v-text-field
                          outlined
                          dense
                          v-model="list.question"
                          label="Question"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-radio-group v-model="list.isCorrect" column>
                          <v-radio label="True" value="True"></v-radio>
                          <v-radio label="False" value="False"></v-radio>
                        </v-radio-group>
                      </v-flex>
                      <v-flex xs12 pt-5>
                        <v-layout wrap justify-center>
                          <v-flex xs6 sm6 md4>
                            <v-btn
                              block
                              dark
                              depressed
                              color="red"
                              @click="edit(list)"
                              >UPDATE</v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs2 sm1 text-right>
                  <v-dialog v-model="list.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-on="on" v-bind="attrs"
                        >mdi-close</v-icon
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        question?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="list.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(list)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-else>
      <v-flex xs12 py-16 text-center>
        <span style="font-family: poppinsbold; font-size: 25px"
          >No questions available</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      radioGroup: "",
      data: [],
      questions: [
        {
          question: "",
          answer: "",
        },
      ],
      selectedOption: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/questions/trueorfalse/list/course",
        method: "GET",
        params: {
          courseId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.questions = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeQuestion(l) {
      this.questions.splice(l, 1);
    },
    edit(list) {
      this.appLoading = true;
      var user = {};
      user["question"] = list.question;
      user["answer"] = list.isCorrect;
      user["id"] = list._id;

      axios({
        url: "/question/trueorfalse/edit/course",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      axios({
        url: "/question/trueorfalse/delete/course",
        method: "POST",
        data: {
          id: r._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    statusChange(id) {
      axios({
        url: "/question/trueorfalse/change/status/course",
        method: "POST",
        data: {
          id: id._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Status Changed Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>