<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title
            class="elevation-1"
            style="font-family: poppinsextrabold"
          >
            Questions Details
            <v-spacer></v-spacer>
            <v-flex xs6 text-right>
              <v-btn color="green" :to="'/question?id=' + $route.query.id">
                <span style="color: white"> Add More Questions</span>
              </v-btn>
            </v-flex>
          </v-card-title>
          <div class="pt-4">
            <v-tabs
              v-model="tab"
              background-color="#DFFFDBCC"
              color="#3CB043"
              dark
              grow
            >
              <v-tab v-for="(item, i) in items" :key="i">
                <span
                  style="font-family: poppinsmedium"
                  :style="tab == i ? 'color: black' : 'color: #635E5E'"
                >
                  {{ item }}</span
                >
              </v-tab>
              <v-tab-item>
                <McqQuestionView />
              </v-tab-item>
              <v-tab-item>
                <EssayQuestionView />
              </v-tab-item>
              <v-tab-item>
                <TrueORfalseQuestionView />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import McqQuestionView from "./mcqQuestionView";
import EssayQuestionView from "./essayQuestionView";
import TrueORfalseQuestionView from "./trueORfalseQuestionView";
export default {
  components: {
    McqQuestionView,
    EssayQuestionView,
    TrueORfalseQuestionView,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      radioGroup: "",
      data: [],
      tab: null,
      items: ["MCQ", "Essay", "True/False"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      questions: [
        {
          question: "",
          options: [{ option: "", isCorrect: false }],
        },
      ],
      selectedOption: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/questions/list",
        method: "GET",
        params: {
          moduleId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.questions = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addOption(l) {
      // const newOptionId = this.options.length + 1;
      const newOption = { option: " ", isCorrect: false };
      this.questions[l].options.push(newOption);
    },
    addQuestion() {
      const newOption = {
        question: " ",
        options: [{ option: " ", isCorrect: false }],
      };
      this.questions.push(newOption);
    },
    removeOption(l, i) {
      this.questions[l].options.splice(i, 1);
    },
    removeQuestion(l) {
      this.questions.splice(l, 1);
    },
    edit(list) {
      this.appLoading = true;
      var user = {};
      user["question"] = list.question.question;
      user["options"] = list.options;
      user["id"] = list.question._id;

      axios({
        url: "/question/edit",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      axios({
        url: "/question/delete",
        method: "POST",
        data: {
          id: r.question._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    statusChange(id) {
      axios({
        url: "/question/change/status",
        method: "POST",
        data: {
          id: id.question._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Status Changed Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>
  