<template>
  <div>
    <v-layout wrap v-if="questions.length > 0">
      <v-flex xs12>
        <v-form>
          <v-card-text>
            <v-container>
              <v-layout
                wrap
                justify-center
                v-for="(list, l) in questions"
                :key="l"
              >
                <v-flex xs10 sm11 pa-2>
                  <v-card>
                    <v-layout wrap justify-center pa-5>
                      <v-flex xs12 sm12 md12>
                        <v-layout wrap justify-end>
                          <v-flex xs12 sm11 text-right pa-2>
                            <span
                              style="
                                font-size: 15px;
                                font-family: poppinsmedium;
                              "
                              >Change Status</span
                            >
                          </v-flex>
                          <v-flex xs12 sm2 md1 text-right pa-2>
                            <v-btn
                              color="green"
                              small
                              depressed
                              @click="statusChange(list)"
                              ><span
                                style="color: white; font-family: poppinsmedium"
                                >{{ list.question.status }}</span
                              ></v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-5>
                        <v-text-field
                          outlined
                          dense
                          v-model="list.question.question"
                          label="Question"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex
                            xs12
                            sm12
                            pt-2
                            v-for="(item, i) in list.options"
                            :key="i"
                          >
                            <v-layout wrap>
                              <v-flex xs2 sm1 pt-2>
                                <v-checkbox
                                  v-model="item.isCorrect"
                                  hide-details
                                  class="shrink mr-2 mt-0"
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs8 sm10 text-left>
                                <v-text-field
                                  dense
                                  v-model="item.option"
                                  label="Option"
                                  required
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs2 sm1 pt-4 text-right>
                                <v-icon
                                  small
                                  color="red"
                                  @click="removeOption(l, i)"
                                  >mdi-close</v-icon
                                >
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-flex text-right pt-5>
                          <v-btn
                            small
                            dark
                            @click="addOption(l)"
                            color="green"
                            style="font-family: poppinsmedium"
                            >Add Option</v-btn
                          >
                        </v-flex>
                      </v-flex>
                      <v-flex xs12 pt-5>
                        <v-layout wrap justify-center>
                          <v-flex xs6 sm6 md4>
                            <v-btn
                              block
                              dark
                              depressed
                              color="red"
                              @click="edit(list)"
                              >UPDATE</v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs2 sm1 text-right>
                  <v-dialog v-model="list.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-on="on" v-bind="attrs"
                        >mdi-close</v-icon
                      >
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        question?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="list.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(list)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-else>
      <v-flex xs12 py-16 text-center>
        <span style="font-family: poppinsbold; font-size: 25px"
          >No questions available</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      radioGroup: "",
      data: [],
      tab: null,
      items: ["MCQ", "Essay", "True/False"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      questions: [
        {
          question: "",
          options: [{ option: "", isCorrect: false }],
        },
      ],
      selectedOption: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/questions/list/course",
        method: "GET",
        params: {
          courseId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.questions = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addOption(l) {
      // const newOptionId = this.options.length + 1;
      const newOption = { option: " ", isCorrect: false };
      this.questions[l].options.push(newOption);
    },
    addQuestion() {
      const newOption = {
        question: " ",
        options: [{ option: " ", isCorrect: false }],
      };
      this.questions.push(newOption);
    },
    removeOption(l, i) {
      this.questions[l].options.splice(i, 1);
    },
    removeQuestion(l) {
      this.questions.splice(l, 1);
    },
    edit(list) {
      this.appLoading = true;
      var user = {};
      user["question"] = list.question.question;
      user["options"] = list.options;
      user["id"] = list.question._id;

      axios({
        url: "/question/edit/course",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      axios({
        url: "/question/delete/course",
        method: "POST",
        data: {
          id: r.question._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    statusChange(id) {
      axios({
        url: "/question/change/status/course",
        method: "POST",
        data: {
          id: id.question._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Status Changed Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>